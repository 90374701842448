@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.main-container {
  display: flex;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

  .giphy-embed {
    height: 100vh;
    width: 96%;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 2%;
    right: 2%;
    bottom: 0;
    z-index: -2;
  }

  .firstpart {
    height: 100vh;
    background: white;
    opacity: 0.7;
    width: 49.7%;
    display: flex;
    justify-content: center;
    align-items: center;

    .info {
      padding: 15%;

      h1 {
        position: relative;
      }

      h1::before {
        content: " ";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, gold, yellow, transparent);
      }

      td {
        padding: 10px;
      }

      i {
        font-size: 60px;
      }
    }
  }

  .middlepart {
    height: 100vh;
    background: gold;
    opacity: 0.9;
    width: 0.6%;
  }

  .lastpart {
    height: 100vh;
    width: 49.7%;
    background-color: transparent;
    position: relative;

    .subpart {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      font-size: 18px;

      .logo {
        width: 200px;
        filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, 0.7));
      }

      .login-box {
        padding: 30px;
        background: white;
        width: 350px;
        height: auto;
        border-radius: 10px;

        h2 {
          position: relative;
        }

        h2::before {
          content: " ";
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
          height: 2px;
          background: linear-gradient(to right, gold, yellow, transparent);
        }

        .forgot {
          display: flex;
          justify-content: space-between;
          margin: 20px 0;
        }

        .remember-me {
          display: inline-flex;
          margin: 0;
        }

        .register {
          margin-top: 20px;
        }
      }
    }
  }

  .lastpart::before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1bb380;
    opacity: 0.8;
    z-index: -1;
  }
}

@media (max-width: 968px) {
  .main-container {
    flex-direction: column;

    .firstpart {
      height: auto;
      width: 100%;

      .info {
        padding: 10%;
      }
    }

    .middlepart {
      display: none;
    }

    .lastpart {
      height: 100vh;
      width: 100%;
      // padding: 10% 0;
    }
  }
}

@media (max-width: 564px) {
  .main-container {
    .giphy-embed {
      display: none;
    }

    .firstpart {
      display: none;
    }
  }
}